import React from 'react'
import { SummaryWrapper } from './styles'
import { AffiliateTransactionURL } from 'commons/API'
import AffiliateAPI from 'commons/API/affiliate'
import { formatMoney } from 'utils'

export default function TransactionSummary () {
  const [data, setData] = React.useState([])
  
  React.useEffect(() => {
    async function fetchdata () {
      try {
        const fetchData = await AffiliateAPI.get(AffiliateTransactionURL.TransactionSummary)
        setData(fetchData.data)
      } catch (error) {
        console.log(error)
      }
    }
    fetchdata()
  }, [])

  return (
    <>
      <SummaryWrapper>
        <div>
          <h4>Total Transaction</h4>
          <p>{ data.total_transaction }</p>
        </div>
        <div>
          <h4>Paid Transaction</h4>
          <p>{ data.paid_transaction }</p>
        </div>
        <div>
          <h4>Unpaid Transaction</h4>
          <p>{ data.unpaid_transaction }</p>
        </div>
        <div>
          <h4>Total Commission</h4>
          <p>{ formatMoney(data.total_commission_idr, { currency: 'idr' }) }</p>
          <p>{ formatMoney(data.total_commission_usd, { currency: 'usd' }) }</p>
        </div>
        <div>
          <h4>Paid Commission</h4>
          <p>{ formatMoney(data.paid_commission_idr, { currency: 'idr' }) }</p>
          <p>{ formatMoney(data.paid_commission_usd, { currency: 'usd' }) }</p>
        </div>
        <div>
          <h4>Unpaid Commission</h4>
          <p>{ formatMoney(data.unpaid_commission_idr, { currency: 'idr' }) }</p>
          <p>{ formatMoney(data.unpaid_commission_usd, { currency: 'usd' }) }</p>
        </div>
      </SummaryWrapper>
    </>
  )
}