import styled from 'styled-components'
import { IS_FRAME_MOBILE } from 'utils'

export const SummaryWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  border: 1px solid #dee2ee;
  border-radius: 0.5rem;
  margin-bottom: 2rem;

  p {
    font-size: 12px;
  }

  ${IS_FRAME_MOBILE} {
    flex-flow: wrap;
    margin-top: 1rem;
  }
`