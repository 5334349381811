import React from 'react'
import { Breadcrumbs, AffiliateDashButton } from 'components/AffiliateDashboard'
import { Wrapper, HeaderWrapper } from './styles'
import { Link } from 'gatsby'
import TransactionSummary from './components/TransactionSummary'
import TransactionList from './components/TransactionList'
import qs from 'query-string'

export default function TransactionView ({ location }) {
  const queryParams = location.search

  const clientId = qs.parse(queryParams).client_id
  const pageQuery = qs.parse(queryParams).page
  const perPageQuery = qs.parse(queryParams).per_page

  return (
    <>
      <Wrapper>
        <Breadcrumbs location={ location } />
        <HeaderWrapper>
          <h2>Transaction</h2>
          <Link to="/affiliate-dashboard/payouts">
            <AffiliateDashButton type="primary">Payouts</AffiliateDashButton>
          </Link>
        </HeaderWrapper>
        <TransactionSummary />
        <TransactionList
          clientId={ clientId }
          pageQuery={ pageQuery }
          perPageQuery={ perPageQuery }
        />
      </Wrapper>
    </>
  )
}