import { AffiliateDashboardLayout } from 'components/AffiliateDashboard'
import TransactionView from 'domains/AffiliateDashboard/Transactions'
import React from 'react'
import withAuth from '../../hocs/with-auth'

const redirectUrl = '/affiliate-dashboard/login?redirectUrl=/affiliate-dashboard/transactions'

const Transactions = ({ location }) => (
  <AffiliateDashboardLayout>
    <TransactionView location={ location }/>
  </AffiliateDashboardLayout>
)

export default withAuth(Transactions, redirectUrl)
