import styled from 'styled-components'
import { IS_FRAME_MOBILE, IS_FRAME_TABLET } from 'utils'
import { greyishBrown } from 'components/baseColor'

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`

const STATUS_COLOR_MAPPING = {
  Success : '#06ab02',
  Failed  : '#e05050'
}

export const Status = styled.div`
  color: ${({ status }) => status ? STATUS_COLOR_MAPPING.Success : STATUS_COLOR_MAPPING.Failed};
  font-weight: bold;
`

export const DesktopWrapper = styled.div`
  display: block;

  ${IS_FRAME_TABLET} {
    display:none;
  }
  
  ${IS_FRAME_MOBILE} {
    display:none;
  }
`

export const MobileWrapper = styled.div`
  display:none;
  
  ${IS_FRAME_TABLET} {
    display: block;
  }

  ${IS_FRAME_MOBILE} {
    display: block;
  }
`

export const HistoryCard = styled.div`
  border: 1px solid #dee2ee;
  border-radius: 0.75rem;
  padding: 1.5rem;
  margin: 1rem 0;
  background-color: #f8f8f8;
  color: ${greyishBrown};

  > div {
    margin: 0.5rem 0;
    display: flex;
    justify-content: space-between;
    font-size: 0.85rem;
  }
`

export const Key = styled.div`
  font-weight: bold;
  flex: 0 0 45%;
  letter-spacing: 0.25px;
  line-height: 1.25;
  font-size: 0.875rem;
`

export const Value = styled.div`
  flex: 0 0 50%;
  text-align: right;
  letter-spacing: 0.25px;
  line-height: 1.25;
`
