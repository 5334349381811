import { lightDarkBlue } from 'components/baseColor'
import styled from 'styled-components'
import { IS_FRAME_MOBILE, IS_FRAME_TABLET } from 'utils'

export const Wrapper = styled.div`
  margin: 1rem 8rem;
  font-family: 'Open Sans', system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  color: ${lightDarkBlue};
  min-height: 75vh;

  h2 {
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.25px;
    font-size: 1.4rem;
  }

  ${IS_FRAME_MOBILE} {
    margin: 1rem 1rem 2rem;

    > h2 {
      font-size: 1.1rem;
    }
  }

  ${IS_FRAME_TABLET} {
    margin: 1rem 6rem;
  }
`

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    text-align: right;
  }

  ${IS_FRAME_MOBILE} {
    margin-top: 1rem;
    align-items: baseline;

    > h2 {
      margin: 0;
    }
  }

  ${IS_FRAME_TABLET} {
    align-items: baseline;
  }
`
