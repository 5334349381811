export const TITLES = [
  {
    name     : 'Date',
    withSort : true,
    get key() {
      return this.name.toLowerCase()
    }
  },
  {
    name       : 'Client',
    withFilter : true,
    width      : '120px',
    get key() {
      return this.name.toLowerCase()
    }
  },
  {
    name: 'Package',
    get key() {
      return this.name.toLowerCase()
    }
  },
  {
    name: 'Commission',
    get key() {
      return this.name.toLowerCase()
    }
  },
  {
    name       : 'Status',
    withFilter : true,
    width      : '100px',
    get key() {
      return this.name.toLowerCase()
    }
  },
  {
    name  : 'Invoice',
    width : '140px',
    key   : 'invoice'
  }
]