import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { 
  Pagination, 
  ShowPerPage,
  Table,
  EmptyState
} from 'components/AffiliateDashboard'
import { Flex, Status, DesktopWrapper, MobileWrapper, HistoryCard, Key, Value } from './styles'
import { AffiliateTransactionURL } from 'commons/API'
import AffiliateAPI from 'commons/API/affiliate'
import { TITLES } from './constant'
import { navigate } from 'gatsby'
import { useToasts } from 'react-toast-notifications'
import dayjs from 'dayjs'
import { formatMoney } from 'utils'

export default function TransactionList ({
  clientId,
  pageQuery,
  perPageQuery
}) {

  const { addToast } = useToasts()

  const [transactionList, setTransactionList] = useState({
    affiliate_status: {
      id   : '',
      name : ''
    },
    affiliate_balances : [],
    meta               : {
      total      : 0,
      total_page : 1
    }
  })

  const [list, setList] = useState()
  
  useEffect(() => {
    AffiliateAPI.get(AffiliateTransactionURL.TransactionClients)
      .then(async (res) => {
        const filteredData = await res.data.clients.reduce((unique, o) => {
          if(!unique.some((obj) => obj.id === o.id && obj.company_name === o.company_name)) {
            unique.push(o)
          }
          return unique
        },[])
        filteredData.forEach((el) => { el.checked = false })
        setList(filteredData)
      })
      .catch((error) => {
        addToast(error.response.data.message, { appearance: 'warning' })
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  
  const [filter, setFilter] = useState(
    {
      '$transaction.account.id$' : [],
      'is_paid'                  : []
    }
  )

  const onFilterCompany = (checkedAccount) => {
    const checkedAccountId = filter['$transaction.account.id$'] 
    if (checkedAccountId.includes(checkedAccount)) {
      const index = checkedAccountId.indexOf(checkedAccount)
      if (index > -1) {
        checkedAccountId.splice(index, 1)
        setFilter((prev) => ({
          ...prev,
          '$transaction.account.id$': checkedAccountId
        }))
      }
    } else {
      setFilter((prev) => ({
        ...prev,
        '$transaction.account.id$': [...prev['$transaction.account.id$'], checkedAccount]
      }))
    }
  }

  const onFilterStatus = (checkedIsPaid) => {
    setFilter((prev) => ({
      ...prev,
      'is_paid': checkedIsPaid === 'all' ? [] : [checkedIsPaid]
    }))
  }
  
  const [isLoading, setIsLoading] = useState(false)
  
  const fetchTransactionList = useCallback(async (pageQuery, perPageQuery) => {
    setIsLoading(true)
    await AffiliateAPI.post(AffiliateTransactionURL.TransactionList, 
      {
        filter: filter
      },
      {
        params: {
          per_page : perPageQuery || 10,
          page     : pageQuery || 1
        }
      }
    )
      .then((response) => {
        const data = response.data
        setTransactionList(data)
        setIsLoading(false)
      })
      .catch((error) => {
        addToast(error.response.data.message, { appearance: 'warning' })
      } )
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.is_paid, filter['$transaction.account.id$']])

  useEffect(() => {
    fetchTransactionList(pageQuery, perPageQuery)
  }, [fetchTransactionList, pageQuery, perPageQuery, filter])

  const handleFormatDataPerRow = useCallback(() => transactionList.affiliate_balances.map((transaction) => (
    {
      date       : dayjs(transaction.created_at).format('DD MMM YYYY'),
      package    : transaction.transaction.account_package?.name,
      status     : <Status status={ transaction.is_paid }>{ transaction.is_paid ? 'Paid' : 'Unpaid' }</Status>,
      client     : transaction.transaction.account.company_name ? transaction.transaction.account.company_name : 'Deleted client',
      commission : formatMoney(transaction.amount, { currency: transaction.currency.name }),
      invoice    : transaction.transaction.invoice_no
    }
  )), [transactionList.affiliate_balances])

  const dataPerRow = useMemo(() => handleFormatDataPerRow(), [handleFormatDataPerRow])

  const handleSort = async (e) => {
    setIsLoading(true)
    await AffiliateAPI.post(AffiliateTransactionURL.TransactionList, 
      {
        filter: filter
      },
      {
        params: {
          per_page : perPageQuery || 10,
          page     : pageQuery || 1
        }
      }
    )
      .then((response) => {
        const data = response.data
        if (e.sortType === 'desc') {
          const sortedDesc = data.affiliate_balances.sort((a, b) => a.created_at.localeCompare(b.created_at))
          setTransactionList({ ...data, affiliate_balances: sortedDesc })
        } else {
          const sortedAsc = data.affiliate_balances.sort((a, b) => a.created_at.localeCompare(a.created_at))
          setTransactionList({ ...data, affiliate_balances: sortedAsc })
        }
        setIsLoading(false)
      })
      .catch((error) => {
        // addToast(error.response.data?.message, { appearance: 'warning' })
        console.log(error)
      } )
  }

  const handleOnchangeShowPage = (number) => {
    const query = [`client_id=${clientId}`, `page=${1}`, `per_page=${number}` ].join('&')
    const updatedUrl = [location.pathname,'?', query].join('')
    navigate(updatedUrl)
  }

  const [checkedStatus, setCheckedStatus] = useState({
    all    : false,
    paid   : false,
    unpaid : false
  })

  const MobileBillingHistory = () => {

    if(!isLoading && !transactionList.affiliate_balances.length) return <EmptyState text={ 'Sorry, you don\'t have transaction history yet' } />

    return (
      transactionList.affiliate_balances.map((transaction, id) => (
        <HistoryCard key={ id }>
          { /* col 1 */ }
          <div>
            <Key>
              Date
            </Key>
            <Value>
              { dayjs(transaction.created_at).format('DD MMM YYYY') }
            </Value>
          </div>

          <div>
            <Key>
              Client
            </Key>
            <Value>
              { transaction.transaction.account.company_name ? transaction.transaction.account.company_name : 'Deleted client' }
            </Value>
          </div>

          <div>
            <Key>
              Package
            </Key>
            <Value>
              { transaction.transaction.account_package?.name }
            </Value>
          </div>

          { /* col 2 */ }
          <div>
            <Key>
              Commission
            </Key>
            <Value>
              { formatMoney(transaction.amount, { currency: transaction.currency.name }) }
            </Value>
          </div>

          <div>
            <Key>
              Status
            </Key>
            <Value>
              { formatMoney(transaction.amount, { currency: transaction.currency.name }) }
            </Value>
          </div>

          <div>
            <Key>
              Status
            </Key>
            <Status status={ transaction.is_paid }>
              { transaction.is_paid ? 'Paid' : 'Unpaid' }
            </Status>
          </div>

          <div>
            <Key>
              Invoice
            </Key>
            <Value>
              { transaction.transaction.invoice_no }
            </Value>
          </div>
        </HistoryCard>
      ))
    )
  }

  return (
    <>
      <Flex>
        <ShowPerPage 
          selected={ perPageQuery }
          onChange={ (e) => handleOnchangeShowPage(e) }
        />
        <Pagination
          currentNumber={ Number(pageQuery) || 1 }
          lastNumber={ transactionList.meta.total_page || 1 }
          total={  transactionList.meta.total } 
          onClick={ (number) => {
            const query = [`client_id=${clientId}`, `page=${number}`, `per_page=${perPageQuery||10}` ].join('&')
            const updatedUrl = [location.pathname,'?', query].join('')
            navigate(updatedUrl)
          } }
        />
      </Flex>

      <DesktopWrapper>
        <Table
          titles={ TITLES }
          data={ dataPerRow }
          isLoading={ isLoading }
          maxHeight='100%'
          list={ list }
          setList={ setList }
          onFilterCompany={ onFilterCompany }
          onFilterStatus={ onFilterStatus }
          handleSort={ handleSort }
          checkedStatus={ checkedStatus }
          setCheckedStatus={ setCheckedStatus }
        />
      </DesktopWrapper>

      <MobileWrapper>
        <MobileBillingHistory />
      </MobileWrapper>
    </>
  )
}